import { ORGANIZATION } from './organization'
import { ONBOARDING_STAGES } from './onboarding-stages'

export const USER_ROLES = `
  userRoles {
    organization {
      ${ORGANIZATION}
    }
    id
    role {
      id
      name
    }
  }
`
export const USER_ORGANIZATIONS = `
  organizations {
    ${ORGANIZATION}
  }
`

export const USER = `
  id
  email
  photo
  token
  firstName
  lastName
  resetPasswordToken
  admin
  calendarLink
  calendarLinkOptinFlow
  questionnaireAnswers
  phone
  personalPhoneNumber
  phoneTransferStatus
  skVesselId
  createdAt
  createdAt
  contactSync
  lastContactsImported
  userRoles {
    id
    role {
      id
      name
    }
  }
  widgetApiKey
  ${USER_ORGANIZATIONS}
  shouldShowMarketingHubDropdown
  canAccessMarketingHub
  isMembershipActive
  isWebsitesActive
  isConvosActive
  isMarketingHubLogin
  questionnaireStep
  chargebeeCustomerId
  receiveNotifications
`

export const LOGIN = `
  user {
    ${USER}
  }
  onboardingStages {
    ${ONBOARDING_STAGES}
  }
`

export const USER_MESSAGES = `
  messages {
    id
  }
`
